import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"demo-space-x"},[_c(VMenu,{attrs:{"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Scale Transition ")])]}}])},[_c(VList,_vm._l((_vm.items),function(item,i){return _c(VListItem,{key:i},[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c(VMenu,{attrs:{"transition":"slide-x-transition","bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Slide X Transition ")])]}}])},[_c(VList,_vm._l((_vm.items),function(item,i){return _c(VListItem,{key:i},[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c(VMenu,{attrs:{"transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Slide Y Transition ")])]}}])},[_c(VList,_vm._l((_vm.items),function(item,i){return _c(VListItem,{key:i},[_c(VListItemTitle,[_vm._v(_vm._s(item.title))])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }