import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"close-on-content-click":false,"nudge-width":200,"max-width":"300","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Menu as Popover ")])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VCard,[_c(VList,[_c(VListItem,[_c(VListItemAvatar,[_c('img',{attrs:{"src":require("@/assets/images/avatars/1.png"),"alt":"John"}})]),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("John Leider")]),_c(VListItemSubtitle,[_vm._v("Founder of Vuetify")])],1),_c(VListItemAction,[_c(VBtn,{class:_vm.fav ? 'error--text' : '',attrs:{"icon":""},on:{"click":function($event){_vm.fav = !_vm.fav}}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiHeartOutline))])],1)],1)],1)],1),_c(VCardText,[_vm._v(" Gingerbread bear claw cake. Soufflé candy sesame snaps chocolate ice cream cake. Dessert candy canes oat cake pudding cupcake. Bear claw sweet wafer bonbon dragée toffee. ")]),_c(VCardActions,[_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiHeartOutline))])],1),_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiBookmarkOutline))])],1),_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,[_vm._v(_vm._s(_vm.icons.mdiShareVariant))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }